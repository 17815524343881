import { Link } from 'react-router-dom';
import '../assets/css/Home.css';
import milles_logo from '../assets/img/milles_logo.svg';
import { Footer } from '../components/Footer/Footer';
import { BookButton } from '../components/BookButton/BookButton';
import { Helmet } from 'react-helmet';
import { useHomePageContent } from '../hooks/useGetHomePageContent';

export const Home = () => {
	const { homePageContent } = useHomePageContent();

	return (
		<>
			<Helmet>
				<title>
					Restaurang Milles - Restaurang på Strandvägen | Milles
				</title>
				<meta
					name="description"
					content="Restaurang Milles har sedan 2013 erbjudit vällagad mat och god dryck i härlig atmosfär och stämning på Strandvägen i Stockholm. Välkommen!"
				/>
				<link rel="canonical" href="https://restaurangmilles.se/" />
			</Helmet>
			<main className="home__wrapper">
				<section className="container">
					<div className="home__content">
						<img src={milles_logo} alt="Milles logo" className="milles__logo" />
						<div className="open__hours__wrapper">
							<h2>{homePageContent?.open_hours_heading || 'Öppettider'}</h2>
							<div className="open__hours">
								{homePageContent?.opening_hours.map((hours, index) => (
									<div key={index}>
										<p>{hours.day_interval || 'Dag'}</p>
										<p>{hours.hours || '00:00 - 00:00'}</p>
									</div>
								)) || (
										<>
											<div>
												<p>Mån</p>
												<p>11:30 - 16:00</p>
											</div>
											<div>
												<p>Tis - Tor</p>
												<p>11:30 - 23:00</p>
											</div>
											<div>
												<p>Fre - Lör</p>
												<p>11:30 - 00:00</p>
											</div>
										</>
									)}
							</div>
                            {/* <br />
                            <em>
                                Under jul- och nyårshelgerna har vi avvikande öppettider. <Link to="/nyar">Läs mer här</Link>
                            </em> */}
						</div>
						<div className="menu">
							<a
								href={homePageContent?.menu_url || '/meny.pdf'}
								target="_blank"
								rel="noopener noreferrer"
							>
								{homePageContent?.menu_text || 'Meny'}
							</a>
							<Link to="/lunch">
								{homePageContent?.lunch_link_text || 'Lunch'}
							</Link>
                            <Link to="/brunch">Brunch</Link>
                            {/* <a
								href={homePageContent?.truffle_menu_url || '/meny.pdf'}
								target="_blank"
								rel="noopener noreferrer"
							>
								Tryffelveckor
							</a> */}
                            {/* <Link to="/nyar">Nyår</Link> */}
						</div>
						<BookButton cta={homePageContent?.book_table_cta || 'Boka bord'} />
						<Footer />
					</div>
				</section>
			</main>
		</>
	);
};
